<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="title"
      class="elevation-1"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <v-col cols="12" sm="10" class="mt-5">
            <v-toolbar-title
              class="error--text text-uppercase font-weight-bold text--darken-1 justify-center"
            >
              <v-icon class="mr-2 mt-n1" color="error"
                >mdi-domain</v-icon
              >Suppliers By Category
            </v-toolbar-title>
            <p class="grey--text ml-8 mt-n2">List of all suppliers by categories</p>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-col>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-divider></v-divider>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="my-1">
          <a
            color="primary"
            class="mx-1 primary--text text--darken-1 text-decoration-underline"
            small
            dark
            @click="viewItem(item)"
          >
          View Suppliers 
          </a>
          
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import SupplierCategoryService from "../../services/supplier_category.service";
import TenderService from "../../services/tender.service";

export default {
  components: {},
  data: () => ({
    name: "SupplierCategory",
    required_documents: [],
    loading: true,
    items: [],
    editedIndex: -1,
    editedItem: {
      required_documents: [],
      required_document_ids: [],
    },
    defaultItem: {
      required_documents: [],
      required_document_ids: [],
    },
    remember: 0,
    timeout: 2000,
    dialog: false,
    valid: true,
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
    },
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Description",
        value: "description",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
    // required docs
    likesAllRequiredDocument() {
      return (
        this.editedItem.required_documents.length ===
        this.required_documents.length
      );
    },
    likesSomerequired_documents() {
      return (
        this.editedItem.required_documents.length > 0 &&
        !this.likesAllRequiredDocument
      );
    },
    iconRequired() {
      if (this.likesAllRequiredDocument) return "mdi-close-box";
      if (this.likesSomerequired_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.index();
    this.getAllRequiredDocuments();
  },
  methods: {
    // required docs
    toggleRequired() {
      this.$nextTick(() => {
        if (this.likesAllRequiredDocument) {
          this.editedItem.required_documents = [];
        } else {
          this.editedItem.required_documents = this.required_documents.slice();
        }
      });
    },
    getAllRequiredDocuments() {
      return TenderService.getAllRequiredDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.required_documents = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    openDocument(item) {
      console.log(item);
      this.$router.replace(item.attachment);
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    index() {
      return SupplierCategoryService.index().then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    viewItem(item) {
      this.$router.push("/suppliers-view/"+item.id+"/"+item.title);
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser) {
        SupplierCategoryService.delete(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.items.splice(index, 1)
              this.close();
            } else {
              this.$store.dispatch("alert/error", 'Error deleting document');
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            else if (error.response.status == 500) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            else if (error.response.status == 502) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            else if (error.response.status == 401) {
              this.handleLogout();
            }else{
              this.$store.dispatch("alert/error", error.response.statusText);
            }
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleFileUpload(file) {
      console.log("uploading...");
      await this.getBase64(file).then(
        (data) => (this.editedItem.attachment = data)
      );
      //   this.$tender_service.addDocument(formData).then(
      //     (response) => {
      //       console.log(response);
      //       if (response.status == 200) {
      //         this.$store.dispatch("alert/success", "Tender Document uploaded!");
      //         this.getTender();
      //         this.loading = false;
      //       } else {
      //         this.$store.dispatch("alert/error", response.message);
      //         this.loading = false;
      //       }
      //     },
      //     (error) => {
      //       this.loading = false;
      //       if (error.response.status == 422) {
      //         console.log(error.response.data.errors);
      //         this.message = error.response.data.message;
      //       } else {
      //         this.message =
      //           (error.response && error.response.data) ||
      //           error.message ||
      //           error.toString() ||
      //           "Internal Server Error";
      //       }
      //       this.$store.dispatch("alert/error", this.message);
      //       this.loading = false;
      //     }
      //   );
    },

    save() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        // edit
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          try {
            if (this.editedItem.required_documents[0].id) {
              this.editedItem.required_documents = this.editedItem.required_document_ids;
            }
          } catch (error) {
            console.log(error);
          }
          console.log(this.editedItem);
          SupplierCategoryService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.index();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          // create
          SupplierCategoryService.store(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Item created successfully"
                );
                this.loading = false;
                this.index();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      } else {
        this.loading = false;
        this.index();
      }
    },
  },
};
</script>
